import React, {ReactNode} from "react";
import styles from "./index.module.scss";

interface CheckboxCustomDTO extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxCustomDTO>
((props,ref)=>{

    return <div className={styles.checkbox}>
        <input {...{...props, children:null, className:null}}
               type={"checkbox"}
               ref={ref}
               placeholder={""}
               className={`${styles.input} ${props.className}`}/>
        <label htmlFor={props.name} className={styles.label}>{props.children}</label>
    </div>
})

export default Checkbox;