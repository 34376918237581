import React, {ChangeEvent} from "react";
import styles from './index.module.scss'
import {spaceLimitKeyPress} from "../../lib/keyPress";

interface InputCustomDTO extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    spaceNotAllowFlag?: boolean,
    setValue:(val:string)=>void
}

const Input = React.forwardRef<HTMLInputElement, InputCustomDTO>
((props,ref)=>{


    function onChange(e:ChangeEvent<HTMLInputElement>) {
        const val = e.currentTarget.value;
        props.setValue(val);
        props.onChange && props.onChange(e);
    }

    function onKeyPress(e:React.KeyboardEvent<HTMLInputElement>){
        Boolean(props.spaceNotAllowFlag) && spaceLimitKeyPress(e);
        props.onKeyPress && props.onKeyPress(e);
    }

    function onPaste(e: React.ClipboardEvent<HTMLInputElement>){
        Boolean(props.spaceNotAllowFlag) && function () {
            e.preventDefault()
            var regex = / /gi;
            const val = e.clipboardData.getData('Text').replace(regex, '');
            e.currentTarget.value = val;
            props.setValue(val);
        }()
        props.onPaste && props.onPaste(e);
    }

    return <>
        <input {...props}
            ref={ref}
               placeholder={""}
               className={`${styles.input} ${props.className}`}
               onChange={onChange}
               onKeyPress={onKeyPress}
               onPaste={onPaste}
        />
        <label
            placeholder={props.placeholder}
            htmlFor={props.name}/>
    </>
})

export default Input;