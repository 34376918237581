import styles from './Complete.module.scss'
import Logo from "../../../../component/Logo";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";


export default function SignUpConfirmComplete(){

    return (
            <div className={styles.confirmWrap}>

                <h3>Thanks for confirming your account.</h3>
                <div className={styles.cont}>
                    That’s it, time to ship! <br/> Click the button below to get shipping!
                </div>
                <div className={styles.buttonBtn}>
                    <Link to={"/login"}>
                        <Button variant={"primary"}>
                            Sign in
                        </Button>
                    </Link>
                </div>
            </div>
    )
}