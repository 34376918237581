import {useEffect, useRef, useState} from "react";
import useInterval from "../../hook/useInterval";
import useEffectExceptOnMount from "../../hook/useEffectExceptOnMount";


export default function ThemeLoader({animate, resetCnt}:{animate:boolean, resetCnt?:number}){

    const [opacity, setOpacity] = useState(1);
    const intervalDelay = useRef<number|null>(null);
    const defaultDelay = 20;

    useEffectExceptOnMount(()=>{
        setOpacity(1);
    }, [resetCnt])


    useInterval(()=>{
        animate && setOpacity(prevState=>prevState-0.005)
    }, intervalDelay.current);

    useEffect(()=>{
        !intervalDelay.current && animate && (intervalDelay.current = (defaultDelay));
    }, [animate]);

    useEffectExceptOnMount(()=>{
        if(opacity <= 0.3){
            intervalDelay.current = null;
        }
    }, [opacity]);
    return (
        <div className="theme-loader" style={{opacity: opacity}}>
            <div className="loader-track">
                <div className="loader-bar"/>
            </div>
        </div>
    )
}