import React, {ChangeEvent, useContext, useEffect, useState} from "react";

import styles from './index.module.scss'
import Input from "../../component/Input";
import Checkbox from "../../component/Checkbox";
import useEffectExceptOnMount from "../../hook/useEffectExceptOnMount";
import Logo from "../../component/Logo";
import {Button, Col, FloatingLabel, Form, FormGroup, Row, Spinner} from "react-bootstrap";
import {emailCheck} from "../../lib/validation";
import {useLocation, useNavigate} from "react-router-dom";
import SimpleErrorModal from "../../component/modal/SimpleErrorModal";
import AppErrorModal from "../../component/modal/AppErrorModal";
import {GlobalContext} from "../../App";
import {spaceLimitKeyPress} from "../../lib/keyPress";

function PasswordPolicy({validFlagArr}:{validFlagArr:[boolean,boolean]}){

    return (
        <div className={styles['pw-policy-popover']}>
            <span>Your password must :</span>
            <div className="checkbox-fade fade-in-primary">
                <label>
                    <input type="checkbox"/>
                        <span className={`${validFlagArr[0] ? styles.valid : ""} ${styles.graphics}`}>
                            <i className="cr-icon icofont icofont-ui-check"></i>
                        </span>
                        <span className={styles.text}>be a minium of 8 and a maximum of 15</span>
                </label>
            </div>
            <div className="checkbox-fade fade-in-primary">
                <label>
                    <input type="checkbox"/>
                    <span className={`${validFlagArr[1] ? styles.valid : ""} ${styles.graphics}`}>
                            <i className="cr-icon icofont icofont-ui-check"></i>
                        </span>
                        <span className={styles.text}>{"include a minimum of three of the following mix of character types: alphabetic, numbers, non-alphanumeric symbols, ex) `~!@#$%^&*()_+\\|[]{};:?.><-='?"}</span>
                </label>
            </div>
        </div>
    )

}

function SignUp(){

    const [emailAlreadyFlag, setEmailAlreadyFlag] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErrorFlag, setEmailErrorFlag] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordErrorFlag, setPasswordErrorFlag] = useState(false);
    const [passwordFocusFlag, setPasswordFocusFlag] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErrorFlag, setConfirmPasswordErrorFlag] = useState(false);


    const [passwordValidFlagArr, setPasswordValidFlagArr] = useState<[boolean, boolean]>([false, false]);

    const [name, setName] = useState('');
    const [nameErrorFlag, setNameErrorFlag] = useState(false);

    const [termsChecked, setTermsChecked] = useState(false);
    const [termsErrorFlag, setTermsErrorFlag] = useState(false);

    const [newsChecked, setNewsChecked] = useState(false);

    const [loadingFlag, setLoadingFlag] = useState(false);

    const [upErrorModalFlag, setUpErrorModalFlag] = useState(false);

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    useEffect(()=>{
        console.log("TTT")
    }, []);

    useEffectExceptOnMount(()=>{
        pwdValid();
    }, [password]);

    useEffectExceptOnMount(()=>{
        setEmailErrorFlag(false);
        setEmailAlreadyFlag(false);
    }, [email]);

    function pwdValid(){

        const validFlag:[boolean,boolean] = [false, false];

        validFlag[0] = password.length>=8 && password.length<=15;
        validFlag[1] = /[`~!@#$%^&*()_+\\|\[\]\{\};:\?.><\-\=\'?]/gi.test(password) && /[a-z]/gi.test(password) && /[0-9]/gi.test(password) && !/\s/g.test(password);

        setPasswordValidFlagArr(validFlag);
        setPasswordErrorFlag(false);
    }

    async function emailAlreadyCheck():Promise<boolean>{
        const res = await fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp/userExists`, {
            headers: {"Content-Type": "text/plain"},
            method: "POST",
            body: email
        });

        if(res.ok){

            const resText = await res.text();
            setEmailAlreadyFlag(resText === 'true');

            return resText === 'true';

        }

        globalContext.openAjaxErrorModal();

        return false;
    }

    function emailBlur(){

        if(email.trim() !== '' && emailCheck(email)) {
            emailAlreadyCheck();
        }

    }

    function passwordFocus(){
        setPasswordFocusFlag(true);
    }

    function passwordBlur(){
        setPasswordFocusFlag(false);
        if(passwordValidFlagArr.findIndex(val=>!val) !== -1){
            setPasswordErrorFlag(true);
        }
    }

    function termsChange(e:ChangeEvent<HTMLInputElement>){
        const val = e.currentTarget.checked;
        setTermsChecked(val);
    }
    function newsChange(e:ChangeEvent<HTMLInputElement>){
        const val = e.currentTarget.checked;
        setNewsChecked(val);
    }

    function signUp(){

        setLoadingFlag(true);
        if(emailAlreadyFlag){
            setLoadingFlag(false);
        } else {

            emailAlreadyCheck().then(ret => {

                if(ret){
                    setLoadingFlag(false);
                } else {
                    if(formCheck() > 0) {
                        setLoadingFlag(false);
                    } else {

                        const data = {
                            id: email,
                            pw: password,
                            fullName: name,
                            termsFlag: true,
                            newsFlag: newsChecked
                        }

                        fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp`, {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            method:"POST",
                            body: JSON.stringify(data)
                        }).then(data=>{
                            if(data.ok) {
                                return data.text();
                            }
                            throw new Error("error")
                        }).then(text=>{

                            if(text === '1') {
                                navigate('/signUp/confirm', {state: {id: email}});
                            } else if(text==='test'){
                                navigate('/login');
                            } else {
                                setUpErrorModalFlag(true);
                            }

                        }).finally(()=>{
                            setLoadingFlag(false);
                        }).catch(err=>{
                            globalContext.openAjaxErrorModal();
                        })

                    }
                }


            }).catch(()=>{
                setLoadingFlag(false);
            })

        }

    }

    function formCheck(): number{
        let errCnt = 0;
        if(email == null || !emailCheck(email)) {
            setEmailErrorFlag(true);
            errCnt++;
        }

        if(password == null || password.trim() === '') {
            setPasswordErrorFlag(true);
            errCnt++;
        }

        if(confirmPassword == null || confirmPassword.trim() === '' ) {
            setConfirmPasswordErrorFlag(true);
            errCnt++;
        }

        if(name == null || name.trim() === '') {
            setNameErrorFlag(true);
            errCnt++;
        }

        if(!termsChecked) {
            setTermsErrorFlag(true);
            errCnt++;
        }

        return errCnt;
    }

    return (
        <div>
            <AppErrorModal modalFlag={upErrorModalFlag} modalClose={()=>setUpErrorModalFlag(false)}
                           subTitle={"Send Error"} content={"Sorry! Email Service has an error"}/>
            <section className="login bg-primary common-img-bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="signup-card auth-body mr-auto ml-auto">
                                <form className="md-float-material" id="form">
                                    <div className="logo-text">
                                        <Logo colorFlag={false} px={220}/>
                                    </div>
                                    <div className={`auth-box ${styles['auth-box']}`}>
                                        <Row className={"m-b-20"}/>
                                        <Form.Group className={styles['sign-form-group']}>
                                            <Input type={"text"} placeholder={"Email"} name={"id"} required={true}
                                                   onBlur={emailBlur} spaceNotAllowFlag={true}
                                                   value={email} setValue={setEmail} className={(emailAlreadyFlag||emailErrorFlag)?styles.inputError:""}
                                            />
                                        </Form.Group>
                                        {
                                            emailAlreadyFlag &&
                                            <div className={styles.idAlreadyMsg}>
                                                * Already Exist. Use Another Email
                                            </div>
                                        }
                                        <Form.Group className={styles['sign-form-group']}>
                                            <Input type={"password"}
                                                   onFocus={passwordFocus}
                                                   onBlur={passwordBlur}
                                                   className={passwordErrorFlag?styles.inputError:""}
                                                   placeholder={"Password"} name={"pw"} required={true}
                                                   value={password} setValue={setPassword} maxLength={20}/>
                                        </Form.Group>

                                        {
                                            passwordErrorFlag && <div className={styles.pwErrorMsg}>* Password doesn't meet the password requirements</div>
                                        }

                                        {
                                            passwordFocusFlag && <PasswordPolicy validFlagArr={passwordValidFlagArr}/>
                                        }

                                        <Form.Group className={styles['sign-form-group']}>
                                            <Input type={"password"} placeholder={"Confirm Password"}
                                                   name={"confirmPassword"}
                                                   required={true} className={confirmPasswordErrorFlag?styles.inputError:""}
                                                   value={confirmPassword} setValue={setConfirmPassword} maxLength={20}/>
                                        </Form.Group>
                                        <Form.Group className={styles['sign-form-group']}>
                                            <Input type={"text"} placeholder={"Name"} required={true} name={"name"}
                                                   className={nameErrorFlag?styles.inputError:""}
                                                   value={name} setValue={setName} maxLength={100}/>
                                        </Form.Group>
                                        <div className={styles.checkboxWrapper}>

                                            <Checkbox name={"termsYn"} id={"termsYn"} className={termsErrorFlag?styles.checkboxError:""}
                                                      checked={termsChecked} onChange={termsChange}>
                                                I agree to the <a href="/public/terms"
                                                   target="_blank"
                                                   className={styles["terms-link"]}>Terms of Service</a>
                                                <span style={{color:"red"}}>*</span>
                                            </Checkbox>

                                        </div>
                                        <div className={`m-t-5 ${styles.checkboxWrapper}`}>

                                            <Checkbox name={"newYn"} id={"newYn"}
                                                      checked={newsChecked} onChange={newsChange}>
                                                I agree to receive Newsletters
                                            </Checkbox>

                                        </div>
                                        <Row className={"m-t-20"}>
                                            <Form.Group as={Col} md={"12"}>
                                                <Button style={{width:"100%"}} variant={"primary"} onClick={loadingFlag?undefined:signUp}>
                                                    {
                                                        loadingFlag ?
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"/>
                                                            :
                                                            "Sign up now"
                                                    }
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default SignUp;