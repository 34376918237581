import React, { Profiler} from "react";

import {useContext, useEffect, useLayoutEffect, useReducer, useRef, useState} from "react";
import {Location, useLocation, useNavigate, Link} from "react-router-dom";
import Logo from "../../../component/Logo";

import styles from './index.module.scss'
import {Spinner} from "react-bootstrap";
import useInterval from "../../../hook/useInterval";
import useEffectExceptOnMount from "../../../hook/useEffectExceptOnMount";
import {GlobalContext} from "../../../App";

interface CustomLocationDTO extends Location {
    state: any
}

function SignUpConfirm(){

    let location:CustomLocationDTO = useLocation();
    let navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const [resendBtnLoadingFlag, setResendBtnLoadingFlag] = useState(false);

    const [resendTimer, setResendTimer] = useState(0);
    const [timerStartFlag, timerStartToggle] = useReducer((p) => !p, false);


    useEffect(()=>{
        if(location.state?.id == null) {
            navigate('/login')
        }
    }, [])

    useEffectExceptOnMount(()=>{
        if(resendTimer === 60) timerStartToggle();
        else if (resendTimer <= 0) timerStartToggle();
    }, [resendTimer]);

    useInterval(()=>{
        setResendTimer(prevState=>prevState-1);
    }, timerStartFlag ? 1000 : null);

    function resendClick(){

        setResendBtnLoadingFlag(true);

        const formData = new FormData();
        formData.append('id', location.state.id)

        fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp/resend/${location.state.id}`, {
            method:"POST"
        }).then(data=>{
            if(data.ok)
                return data.text()
            throw new Error("")
        }).then((data)=>{
            if(data !== '1') {
                globalContext.openAjaxErrorModal();
            }
            setResendTimer(60);

        }).catch((error)=>{
            globalContext.openAjaxErrorModal();
        }).finally(()=>{
            setResendBtnLoadingFlag(false);
        })


    }



    return(
            <div>
                <section className="login bg-primary common-img-bg">
                    <div className="container-fluid">
                        <div>
                            <Logo px={200} colorFlag={false}/>
                        </div>
                        <div className={styles.confirmWrapper}>
                            <h2>Check your Email</h2>
                            <div className={styles.cont}>
                                Thanks for signing up for shipgate! We've sent you a confirmation e-mail to
                                <b className={styles.emailDesc}>{location.state?.id}</b> Make sure to check your e-mail and click the confirmation link to activate your account. This link will expire in 24 hours.
                                <br/><br/>
                                if you don't receive your confirmation email,
                                <span className={`${styles.a} ${(resendBtnLoadingFlag || resendTimer > 0) ? styles.disabled:null}`}
                                      onClick={!resendBtnLoadingFlag && resendTimer === 0 ? resendClick : undefined}>
                                please click here to have it resent.
                            </span>
                                {
                                    resendBtnLoadingFlag ? <Spinner variant={"primary"} animation={"border"} size={"sm"}/>
                                        : resendTimer > 0 && <span className={styles.timer}>({resendTimer})</span>
                                }
                            </div>
                            <hr/>
                            <div>
                                Already have a SHIPGATE account?
                                <Link to={"/login"}  className={styles.a}>Sign in</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
    )
}

export default SignUpConfirm