import Modal from "react-bootstrap/Modal";

import styles from './index.module.scss'
import {Button} from "react-bootstrap";

export default function AjaxErrorModal({modalFlag, modalClose}:{modalFlag:boolean, modalClose:()=>void}){


    return(
        <Modal show={modalFlag} onHide={modalClose} dialogClassName={styles.widthSize} centered={true}>
            <Modal.Body style={{padding:15}}>
                <div className={"icon-div"} style={{
                    fontSize: 80,
                    textAlign: "center",
                    color: "rgb(64, 153, 255)"
                }}>
                    <i className="ti-face-sad"/>
                </div>
                <div className="title" style={{
                    textAlign: "center",
                    fontSize: 26,
                    fontWeight: "bold",
                    color: "rgb(90, 90, 90)"
                }}>
                    Oops! Something went wrong
                </div>
                <div className="description" style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontSize: 15
                }}>
                    Please try again or
                    <a href="https://www.shipgate.io/company/contactsales" target="_blank" style={{color: "rgb(64, 153, 255)"}}>
                        {" let us know what happend."}
                    </a>
                </div>
                <div className="close" style={{opacity:"1", textAlign:"center",marginBottom:15, marginTop:30}}>
                    <Button variant={"outline-primary"} onClick={modalClose}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )

}