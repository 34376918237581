
import Logo from "../../Logo";

import styles from './index.module.scss'
import {useEffect, useRef} from "react";
import {generateUUID} from "../../../lib/common";


function Index(){

    useEffect(()=>{

        const id = generateUUID();

        const script = document.createElement("script");

        script.src = process.env.REACT_APP_DOMAIN+"/404/particles.js"
        script.async = true;
        script.id=id

        document.body.appendChild(script);

        return ()=>{
            document.getElementById(id)?.remove();
        }
    }, []);

    return (
        <div>

            <div id="particles-js" className={styles["particles-js"]}></div>

            <div className={styles.logoWrapper}>
                <Logo px={180} colorFlag={false} />
            </div>

            <div className={styles.content}>
                <div className={styles['content-box']}>
                    <div className={styles['big-content']}>
                        <div className={styles['list-square']}>
                            {
                                Array(3).fill(0).map((obj,idx)=><span key={idx} className={styles.square}/>)
                            }
                        </div>
                        <div className={styles['list-line']}>
                            {
                                Array(6).fill(0).map((obj,idx)=><span key={idx} className={styles.line}/>)
                            }
                        </div>
                        <i className={`fa fa-search ${styles['fa-search']}`}/>
                        <div className={styles.clear}/>
                    </div>
                    <h1>Oops! Error 404 not found.</h1>
                    <p>The page you were looking for doesn't exist.<br/>
                        We think the page may have moved.</p>
                </div>
            </div>

        </div>
    )
}

export default Index