import React from "react";
import {Modal} from "react-bootstrap";

import styles from './index.module.scss'

interface DTO {
    modalFlag:boolean,
    modalClose:()=>void,
    subTitle: string,
    content: string
}

export default function AppErrorModal(props:DTO){

    return (
        <Modal show={props.modalFlag} onHide={props.modalClose}
               centered={true}
               dialogClassName={styles.dialog} contentClassName={styles.modalContent}>
            <Modal.Header className={styles.modalHeader} closeButton={true}>
                <Modal.Title className={styles.modalTitle}>
                    <h3>ERROR</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <i className={`fa fa-times ${styles.faTimes}`}/>
                <div className={styles.subTitle}>{props.subTitle}</div>
                <div className={styles.content}>{props.content}</div>
                <div className={`row ${styles.contact}`}>
                    <div className='col-md-6'><i className='fa fa-phone'/> +82-70-4405-7826</div>
                    <div className='col-md-6'><i className="fa fa-envelope-o"/>
                        <a href="mailto:support@shipgate.io">support@shipgate.io</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}