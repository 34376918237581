import * as React from "react";
import {DependencyList} from "react";

/**
 * @description useEffect didupdate, mount 중 mount 제거 update일때만 실행함
 * @param effect
 * @param dependencies
 */
export const useEffectExceptOnMount = (effect:()=>any, dependencies:DependencyList) => {
    const mounted = React.useRef(false);
    React.useEffect(() => {
        if (mounted.current) {
            const unmount = effect();
            return () => unmount && unmount();
        } else {
            mounted.current = true;
        }
    }, dependencies);

    // Reset on unmount for the next mount.
    React.useEffect(() => {
        return () => {mounted.current = false};
    }, []);
};

export default useEffectExceptOnMount;