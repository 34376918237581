import React, {useContext, useEffect, useReducer, useState} from "react";
import Input from "../../component/Input";
import {Button, Spinner} from "react-bootstrap";

import styles from './index.module.scss'
import {emailCheck} from "../../lib/validation";
import FullLogo from "../../component/Logo";
import {useNavigate} from "react-router-dom";
import AppErrorModal from "../../component/modal/AppErrorModal";
import SimpleErrorModal from "../../component/modal/SimpleErrorModal";
import {spaceLimitKeyPress} from "../../lib/keyPress";
import useEffectExceptOnMount from "../../hook/useEffectExceptOnMount";
import useInterval from "../../hook/useInterval";
import {GlobalContext} from "../../App";

function ResendConfirmEmail({email, ajaxErrorModalOpen}:{email:string, ajaxErrorModalOpen:()=>void}){

    const [loadingFlag, setLoadingFlag] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [timerStartFlag, timerStartToggle] = useReducer((p) => !p, false);

    useEffectExceptOnMount(()=>{
        if(resendTimer === 60) timerStartToggle();
        else if (resendTimer <= 0) timerStartToggle();
    }, [resendTimer]);

    useInterval(()=>{
        setResendTimer(prevState=>prevState-1);
    }, timerStartFlag ? 1000 : null);

    function resendConfirmEmail(){

        setLoadingFlag(true);

        fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp/resend/${email}`, {
            method: "POST"
        }).then(data=>data.text()).then(text=>{

            if(text !== '1') {
                ajaxErrorModalOpen();
            }

            setResendTimer(60);

        }).catch((err)=>{
            ajaxErrorModalOpen();
        }).finally(()=>{
            setLoadingFlag(false);
        })
    }

    return (
        <span>
            Please confirm your email address.
            <span className={`${styles.resendBtn} ${(loadingFlag||resendTimer>0)?styles.disabled:null}`}
                  onClick={(loadingFlag||resendTimer>0)?undefined:resendConfirmEmail}>
                resend email
            </span>
            {
                loadingFlag ? <Spinner style={{marginLeft:5}} variant={"danger"} animation={"border"} size={"sm"}/>
                    : resendTimer > 0 && <span className={styles.timer}>({resendTimer})</span>
            }
        </span>
    )
}

function ResetPassword(){

    const [email, setEmail] = useState('');
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [emailErrorFlag, setEmailErrorFlag] = useState(false);

    const [emailConfirmErrFlag, setEmailConfirmErrFlag] = useState(false);
    const [emailSendErrorModalFlag, setEmailSendErrorModalFlag] = useState(false);

    const globalContext = useContext(GlobalContext);

    const navigate = useNavigate();

    useEffect(()=>{
        setEmailErrorFlag(false);
    }, [email])

    function submit(){

        setLoadingFlag(true);
        formCheck().then(errFlag=>{

            if(!errFlag) {
                fetch(`${process.env.REACT_APP_API_DOMAIN}/resetPw/email`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "text/plain"
                    },
                    body: email
                }).then(data=>{
                    if(data.ok)
                        return data.text();
                    throw new Error("");
                }).then(data=>{
                    if(data === '1')
                        navigate('/reset-password/confirm', {state: {id: email}})
                    else {
                        setEmailSendErrorModalFlag(true);
                    }
                }).catch(err=>globalContext.openAjaxErrorModal())
                    .finally(()=>setLoadingFlag(false));
            } else {
                setLoadingFlag(false)
            }

        }).catch(err=>setLoadingFlag(false));

    }

    async function formCheck():Promise<boolean>{

        setEmailConfirmErrFlag(false);
        setEmailErrorFlag(false);

        let errFlag = true;

        if(emailCheck(email)) {

            var res = await fetch(`${process.env.REACT_APP_API_DOMAIN}/resetPw/userExists/${email}`, {
                method:"POST"
            });

            if(res.ok) {

                const text = (await res.text()).trim();

                if(text === 'y'){
                    //확인됨 정상 이후 단계로 넘어감
                } else if(text === '') {
                    setEmailErrorFlag(true);
                    errFlag = false;
                } else if(text === 'n') { //이멜 컨펌안됨
                    errFlag = false;
                    setEmailConfirmErrFlag(true);
                }

            } else {
                globalContext.openAjaxErrorModal();
            }

        } else {
            errFlag = false;
            setEmailErrorFlag(true);
        }
        return errFlag;
    }

    return (
        <section className="login text-center bg-primary common-img-bg">

            <AppErrorModal modalFlag={emailSendErrorModalFlag} modalClose={()=>setEmailSendErrorModalFlag(false)}
                           subTitle={"Send Error"} content={"Sorry! Email Service has an error"}/>
            <div className="container-fluid">

                        <div className={styles.form}>
                            <FullLogo px={200} colorFlag={false}/>
                            <div className="auth-box">
                                <div className="row m-b-20">
                                    <div className="col-md-12">
                                        <h3 className="text-left">Reset your password</h3>
                                    </div>
                                </div>
                                <div className={styles.emailField}>
                                    <Input type="text" required={true} placeholder={"Your Email Address"}
                                           className={emailErrorFlag?"error":""}
                                           value={email}
                                           setValue={setEmail}
                                           spaceNotAllowFlag={true}
                                    />
                                </div>
                                {
                                    (emailErrorFlag || emailConfirmErrFlag) &&
                                    <div className={styles.idMsg}>
                                        * {emailErrorFlag ? "Please enter a valid email address." :
                                        <ResendConfirmEmail email={email}
                                                            ajaxErrorModalOpen={globalContext.openAjaxErrorModal}
                                        />}
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button variant={"primary"} onClick={loadingFlag?undefined:submit} className={`${styles.btn}`}>
                                            {
                                                loadingFlag ?
                                                    <Spinner
                                                    animation="border"
                                                size="sm"/>
                                                    :"Send"
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>


            </div>
        </section>
    );
}

export default ResetPassword;