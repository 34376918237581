import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {Button} from "react-bootstrap";

import loginStyles from './index.module.scss'
import {Link, useNavigate} from "react-router-dom";
import Input from "../../component/Input";
import Checkbox from "../../component/Checkbox";
import {emailCheck} from "../../lib/validation";

import Logo from "../../component/Logo";
import {GlobalContext} from "../../App";


function LoginForm({attemptedIdRef, ajaxErrorModalOpen}: {attemptedIdRef:any, ajaxErrorModalOpen:()=>void}){

    let navigate = useNavigate();

    const rememberRef = useRef<HTMLInputElement>(null);
    const [rememberChecked, setRememberChecked] = useState(false);

    const [loginLoading, setLoginLoading] = useState(false);
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [idError, setIdError] = useState({flag:false, msg:''});
    const [pwError, setPwError] = useState({flag:false, msg:''});

    const signInRef = useRef<HTMLButtonElement>(null);

    const [resendObj, setResendObj] = useState({
        resendFlag : false,
        resendSpinFlag : false,
        resendCheckFlag:false,
        resendTimerText : "",
        resendTimerReal : -1,
        resendTimerClass:"",
        resendClass:"",
    });

    function resend() {

        if(resendObj.resendTimerReal == -1) {

            setResendObj({
                ...resendObj, resendSpinFlag: true, resendTimerReal: 60
            });

            let formData = new FormData();
            formData.append("id", attemptedIdRef.current.value);

            fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp/resend`, {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                body: formData
            }).then((res)=> res.text()).then(data=>{
                if(data === '1') {

                    setResendObj({...resendObj, resendSpinFlag: false, resendCheckFlag: true})

                    setTimeout(()=> {

                        setResendObj(prevState=>({...prevState, resendCheckFlag: false, resendTimerText: "(" + (prevState.resendTimerReal--) +")"}))

                        const checkSyncTime = setInterval(()=>{
                            setResendObj(prevState=>{
                                if(prevState.resendTimerReal == -1) {
                                    clearInterval(checkSyncTime);
                                    return {
                                        ...prevState,
                                        resendTimerText: '',
                                        resendTimerReal: -1
                                    }
                                } else {
                                    return {
                                        ...prevState,
                                        resendTimerText: "(" + (prevState.resendTimerReal--) +")"
                                    }
                                }
                            })

                        },1000);
                    }, 1000);

                }
            }).catch(err=>{
                console.log(err);
                ajaxErrorModalOpen();
                setResendObj({...resendObj, resendTimerReal: -1, resendTimerText: '', resendSpinFlag: false})
            })

        } else {
            setResendObj({...resendObj, resendTimerClass: 'sync-error', resendClass: 'sync-error'});
            setTimeout(()=>{
                setResendObj({...resendObj, resendTimerClass: '', resendClass: ''});
            },1000);
        }

    }

    function inputEnter(event:React.KeyboardEvent){
        if (event.charCode === 13) {
            signInRef.current?.click();
        }
    }
    function idErrorClean(){
        setIdError({flag: false, msg: ''});
    }
    function pwErrorClean(){
        setPwError({flag: false, msg: ''});
    }

    function rememberChange(event:ChangeEvent<HTMLInputElement>){
        const checked = event.currentTarget.checked

        setRememberChecked(checked)
    }

    function signIn(){

        setLoginLoading(true);
    }

    useEffect(()=>{
        if(loginLoading) {
            if(formCheck()) {
                submit();
            } else {
                setLoginLoading(false)
            }
        }
    }, [loginLoading])

    function setCookie(name:string, value:string, days:number) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + days);
        // 설정 일수만큼 현재시간에 만료값으로 지정

        var cookie_value = escape(value) + ((days == null) ? '' : '; expires=' + exdate.toUTCString());
        document.cookie = name + '=' + cookie_value;
    }
    function deleteCookie(name:string) {      document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;';  }

    function submit(){

        const formData = new FormData();
        formData.append("id", id);
        formData.append("pw", pw);

        fetch(`${process.env.REACT_APP_API_DOMAIN}/login`, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            method: 'POST',
            body: formData
        }).then(res=>res.text()).then((data)=>{
            if(data === '1') {
                if(rememberRef.current?.checked) {
                    setCookie('remember', id, 7);
                } else {
                    deleteCookie('remember');
                }
                navigate('/dashboard')
            } else if(data === '0' || data === '-1') {
                setPwError({flag:true, msg: '* Invalid account. Please check and try again'})
            } else if(data === '2') {
                setPwError({flag:true, msg: '* Please confirm your email address.'})
                setResendObj({...resendObj, resendFlag: true, resendSpinFlag: false, resendCheckFlag: false, resendTimerText: ''});
                attemptedIdRef.current.value = id;
            }
        }).catch(err=>{
            console.log(err);
            ajaxErrorModalOpen();
        }).finally(()=>{
            setLoginLoading(false);
        })

    }

    function formCheck(){
        let formFlag = true;

        const idVal = id;
        if(idVal == null || !emailCheck(idVal)) {
            setIdError({flag: true, msg: "* Please enter your email"})
            formFlag = false;
        } else {
            setIdError({flag: false, msg: ""})
        }

        const pwVal = pw;
        if(pwVal == null || pwVal.trim() === '') {
            setPwError({flag: true, msg: "* Please enter your password"})
            formFlag = false;
        } else {
            setPwError({flag: false, msg: ""})
        }

        return formFlag;
    }

    return (
        <div className={`auth-box ${loginStyles['auth-box']}`}>
            <div className="row m-b-20">
            </div>
            <div className={loginStyles['form-group']}>
                <Input type="text"
                       value={id}
                       setValue={setId}
                       placeholder={"Email"}
                       className={(idError.flag?'error':'')} id="id" required onKeyPress={inputEnter} onInput={idErrorClean}/>
            </div>
            <div className={loginStyles.idMsg} style={idError.flag ? {display:'block'}:{display:'none'}}>{idError.msg}</div>
            <div className={loginStyles['form-group']}>
                <Input value={pw}
                       setValue={setPw}
                       placeholder={"Password"}
                       type="password" className={(pwError.flag?'error':'')}  id="password" required
                       onKeyPress={inputEnter}
                       onInput={pwErrorClean}
                />

            </div>
            <div className={loginStyles.pwMsg} style={pwError.flag ? {display:'block'}:{display:'none'}}>
                {pwError.msg}
                <span id={loginStyles['resend-btn']} className={resendObj.resendClass} onClick={resend} style={resendObj.resendFlag?{"display":"inline-block"}:{display: "none"}}>resend email</span>
                <i className='fa fa-spinner fa-spin' style={resendObj.resendSpinFlag?{"display":"inline-block"}:{display: "none"}}></i>
                <i className='fa fa-check' style={resendObj.resendCheckFlag?{"display":"inline-block"}:{display: "none"}}></i>
                <span className={resendObj.resendTimerClass}>{resendObj.resendTimerText}</span>
            </div>
            <div className="row m-t-25 text-left">
                <div className="col-12">
                    <div className={loginStyles['checkbox-wrapper']}>
                        <Checkbox
                            ref={rememberRef}
                            id="remember" name="remember"
                            onChange={rememberChange} checked={rememberChecked}>
                            Remember me
                        </Checkbox>
                    </div>
                    <div className="forgot-phone text-right f-right">
                        <Link to={"/reset-password"}  className={`text-right f-w-600 ${loginStyles['text-inverse']} ${loginStyles.forgotPassword}`}>
                            Forgot Password?
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row m-t-20">
                <div className="col-md-12">
                    <Button variant="primary" className={`${loginStyles['sign-in']} m-b-20`} ref={signInRef} onClick={loginLoading?undefined:signIn} type="button">
                        {
                            loginLoading
                                ?
                                <i className="fa fa-spin fa-spinner"></i>
                                :
                                <span>
                                    Sign In
                                </span>
                        }
                    </Button>
                </div>
            </div>
            <div className={`row ${loginStyles['sign-up-text']}`}>
                <div>
                    Don't have an Account?
                    <Link to={"/sign-up"} ><span>Sign Up</span></Link>
                </div>
            </div>
        </div>
    )
}

export default function Login(){

    const attemptedIdRef = useRef(null);

    const globalContext = useContext(GlobalContext);

    return (
        <div>
            <section className="login p-fixed d-flex bg-primary common-img-bg">

                <div className="container-fluid">
                    <div className={"row"}>
                        <div className="col-sm-12">
                            {/*Authentication card start*/}
                            <div className="login-card card-block auth-body mr-auto ml-auto">
                                <Logo colorFlag={false} px={180}/>
                                <LoginForm attemptedIdRef={attemptedIdRef} ajaxErrorModalOpen={globalContext.openAjaxErrorModal}/>
                            </div>
                        </div>
                        <input type="hidden" id="attemptedId" ref={attemptedIdRef}/>
                    </div>
                </div>

            </section>
        </div>
    )

}