import styles from './Fail.module.scss'


function SignUpConfirmFail(){

    return (
        <div className={styles.confirmWrap}>
            <h3>The email confirmation link is invalid.</h3>
        </div>
    )
}

export default SignUpConfirmFail;