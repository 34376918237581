import {useParams} from "react-router-dom";
import {Profiler, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";

import Logo from "../../../../component/Logo";
import SignUpConfirmFail from "./Fail";
import SignUpConfirmComplete from "./Complete";
import {GlobalContext} from "../../../../App";

function ConfirmOrFail(){

    const {id, confirmCode} = useParams();

    const [confirmOrFail, setConfirmOrFail] = useState<'confirm'|'fail'|null>(null);

    const globalContext = useContext(GlobalContext);

    const runForOnlyOnce = useRef(0);

    useLayoutEffect(()=>{

        runForOnlyOnce.current++;
        if(runForOnlyOnce.current === 1){
            globalContext.themeLoaderReset();
        }
    }, [])
    useEffect(()=>{

        if(runForOnlyOnce.current === 1){
            globalContext.themeLoaderAnimateStart();

            fetch(`${process.env.REACT_APP_API_DOMAIN}/signUp/confirm/${id}/${confirmCode}`, {
                method: "POST"
            }).then(data=>data.text()).then((text)=>{

                if(text === 'true') {
                    setConfirmOrFail("confirm")
                } else setConfirmOrFail("fail")
            }).catch(err=>{
                globalContext.openAjaxErrorModal();
            }).finally(()=>{
                globalContext.themeLoaderHide();
            })
        }

    }, []);


    return (
        <div>
            <section className="login bg-primary common-img-bg">
                <div className="container-fluid">
                    <div className="mr-auto ml-auto">
                        <Logo colorFlag={false} px={200}/>
                    </div>
                    {
                        confirmOrFail === 'confirm' ?
                            <SignUpConfirmComplete/>
                            : confirmOrFail === 'fail' && <SignUpConfirmFail/>
                    }
                </div>
            </section>
        </div>
    )
}

export default ConfirmOrFail;