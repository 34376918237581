import React, {createContext, Profiler, useLayoutEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import AjaxErrorModal from "./component/modal/AjaxErrorModal";
import ThemeLoader from "./component/ThemeLoader";

export const GlobalContext = createContext({
    openAjaxErrorModal:()=>{},
    themeLoaderAnimateStart:()=>{},
    themeLoaderAnimateStop:()=>{},
    themeLoaderReset:()=>{},
    themeLoaderHide:()=>{}
})

function App(props:any) {

    const [ajaxErrorModalFlag, setAjaxErrorModalFlag] = useState(false);
    const [themeLoader, setThemeLoader] = useState({animate:false, restartCnt:0, hide: true});
    const [contextValue, setContextValue] = useState({
        openAjaxErrorModal:()=>setAjaxErrorModalFlag(true),
        themeLoaderAnimateStart:()=>setThemeLoader(prevState=>({...prevState, animate: true})),
        themeLoaderAnimateStop:()=>setThemeLoader(prevState=>({...prevState, animate: false})),
        themeLoaderReset:()=>setThemeLoader(prevState =>({animate: false, hide: false, restartCnt: +1})),
        themeLoaderHide:()=>setThemeLoader(prevState => ({...prevState, hide: true}))
    });

    return (
        <GlobalContext.Provider value={contextValue}>
            <AjaxErrorModal modalFlag={ajaxErrorModalFlag} modalClose={()=>setAjaxErrorModalFlag(false)}/>
            {
                !themeLoader.hide &&
                <ThemeLoader animate={themeLoader.animate} resetCnt={themeLoader.restartCnt} />
            }
            {
                props.children
            }
        </GlobalContext.Provider>
    );
}

export default App;
