import React, {useEffect, useLayoutEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "../pages/login";
import ResetPassword from "../pages/reset-password";
import ResetPasswordConfirm from "../pages/reset-password/confirm";
import ConfirmOrFail from "../pages/sign-up/confirm/confirmOrFail/index";
import App from "../App";
import SignUp from "../pages/sign-up";
import SignUpConfirm from "../pages/sign-up/confirm";
import NotFound from "./error/NotFound";


export function Router(){

    return (
        <BrowserRouter>
            <Routes>
                <Route path={"*"} element={<NotFound/>} />
                <Route path={"/"} element={<Login/>}/>
                <Route path={"/login"} element={<Login/>}/>
                <Route path={"/reset-password"} element={<ResetPassword/>}/>
                <Route path={"/reset-password/confirm"} element={<ResetPasswordConfirm/>}/>

                <Route path={"/sign-up"} element={<SignUp/>}/>
                <Route path={"/signUp"} element={<SignUp/>}/>

                <Route path={"/sign-up/confirm"} element={<SignUpConfirm/>}/>
                <Route path={"/signUp/confirm"} element={<SignUpConfirm/>}/>

                <Route path={"/sign-up/confirm/:id/:confirmCode"} element={<ConfirmOrFail/>}/>
                <Route path={"/signUp/confirm/:id/:confirmCode"} element={<ConfirmOrFail/>}/>
            </Routes>
        </BrowserRouter>
    )
}