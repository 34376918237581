import React from "react";
import  styles from './index.module.scss'

export default function Logo({px, colorFlag}: {px: number, colorFlag: boolean}){
    return (
        <div className="logo-text">
            <img src={`https://cdn.shipgate.io/logo/full_logo_${colorFlag?"color":"white"}.png`}
                 className={styles.img} style={{ "--px": px+"px" } as React.CSSProperties}/>
        </div>
    )
}